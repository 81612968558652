<template>
  <v-app id="inspire">
    <v-app-bar dark :value="$store.getters.getNavStatus" app>
      <v-app-bar-nav-icon to="/Welcome"
        ><v-icon>mdi-dots-grid</v-icon></v-app-bar-nav-icon
      >

      <v-toolbar-title>{{ $store.getters.company.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        open-on-hover
        transition="slide-x-transition"
        bottom
        right
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar>
              <img
                :src="$store.getters.profile.Picture"
                v-if="$store.getters.profile.Picture"
              />
              <v-icon v-else>mdi-account-circle-outline</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <template>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ this.$store.state.profile.name }}
                </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="ChangePasswordbtn">
              <v-list-item-avatar>
                <v-icon>mdi-lock</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Cambiar clave </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Salir </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-app-bar>

    <v-main  class="bg-app" >
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <ChangePassword
      table="users"
      :item="item"
      :dialog="dialog"
      :newItem="false"
      @close="dialog = false"
    />

    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      Una actualización disponible.
      <v-btn text @click="refreshApp"> ACTUALIZAR </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from "./mixins/update";
 import { webserver } from "./services/webserver.js";
import ChangePassword from "./components/Change_password.vue";
import createItem from "./utils/createUser.js";

const TIME_IN_BOUNDARY = 8;
const TIME_OUT_BOUNDARY = 24;
const INTERVAL = 900 * 1000;

export default {
  data: () => ({
    updateExists: false,
    company: null,
    dialog: false,
    profile: { name: null },
    drawer: true,
    item: createItem(),
    navStatus: true,
    open: [],
    icons: {
      project: "mdi-text-box-search-outline",
      weather: "mdi-weather-partly-snowy-rainy",
      crops: "mdi-sprout-outline",
      poly: "mdi-vector-polygon",
      dB: "mdi-database-cog-outline",
      chart: "mdi-chart-bell-curve-cumulative",
      clt: "mdi-account-circle-outline",
      set: "mdi-cog-transfer-outline",
      info: "mdi-book-information-variant",
      cale: "mdi-calendar-month-outline",
      invoice: "mdi-receipt",
      pedido: "mdi-file-document-edit-outline",
      truck: "mdi-truck-fast-outline",
      warehouse: "mdi-warehouse",
      inventory: "mdi-package-variant-closed",
      supplier: "mdi-truck-check-outline",
      orders: "mdi-file-document-edit-outline",
      store: "mdi-store-outline",
      clock: "mdi-clock-check-outline",
      map: "mdi-map-marker-radius",
      home: "mdi-home-circle-outline",
      forklift: "mdi-forklift",
      cash: "mdi-cash-register",
      employees: "mdi-account-multiple-outline",
      account: "mdi-account-lock-outline",
      accountGroup: "mdi-account-group-outline",
      account_tie: "mdi-account-tie",
      fish: "mdi-fish",
    },
    menu: {
      Sales: [
        {
          id: 0,
          name: "Home",
          icon: "home",
          to: "/",
          color: "#CDDC39",
        },
        {
          id: 20,
          name: "Restaurantes",
          icon: "store",
          color: "#FFCA28",
          to: "Pos",
        },
        {
          id: 22,
          name: "Proveedores",
          icon: "store",
          color: "#FFCA28",
          to: "Proveedores",
        },
      ],
      Owner: [
        {
          id: 0,
          name: "Home",
          icon: "home",
          to: "/",
          color: "#CDDC39",
        },
        {
          id: 2,
          name: "Compras",
          icon: "truck",
          color: "#FFCA28",
          children: [
            {
              id: 20,
              name: "Crear Orden de compra",
              icon: "store",
              color: "#FFCA28",
              to: "compras",
            },
            {
              id: 22,
              name: "Reporte Ingreso Mercancias",
              icon: "store",
              color: "#FFCA28",
              to: "store",
            },
          ],
        },
        {
          id: 3,
          name: "Inventario",
          icon: "warehouse",
          color: "red",
          children: [
            {
              id: 32,
              name: "Inventario",
              icon: "store",
              color: "#EF5350",
              to: "Inventario",
            },
            {
              id: 33,
              name: "Movimientos de Inventario",
              icon: "store",
              color: "#EF5350",
              to: "store",
            },
            {
              id: 34,
              name: "Garantías",
              icon: "store",
              color: "#EF5350",
              to: "store",
            },
            {
              id: 36,
              name: "Traslado a Promoción",
              icon: "store",
              color: "#EF5350",
              to: "store",
            },
            {
              id: 37,
              name: "Traslado de Bodega",
              icon: "store",
              color: "#EF5350",
              to: "store",
            },
            {
              id: 38,
              name: "Traslados",
              icon: "store",
              color: "#EF5350",
              to: "store",
            },
            {
              id: 39,
              name: "Rotación",
              icon: "store",
              color: "#EF5350",
              to: "store",
            },
          ],
        },
        {
          id: 1,
          name: "Ventas",
          icon: "cash",
          color: "#CDDC39",
          children: [
            {
              id: 11,
              name: "Comprobante de Ingreso",
              icon: "orders",
              to: "Receipt_in",
              color: "#CDDC39",
            },
            {
              id: 12,
              name: "Comprobante de Egreso",
              icon: "orders",
              to: "Receipt_out",
              color: "#CDDC39",
            },

            {
              id: 15,
              name: "Devolución",
              icon: "orders",
              to: "Contents",
              color: "#CDDC39",
            },
          ],
        },
        {
          id: 4,
          name: "Configuración",
          icon: "set",
          children: [
            {
              id: 42,
              name: "Centros Operativos",
              icon: "cash",
              to: "Pos",
              color: "warning",
            },
            {
              id: 43,
              name: "Catalogo",
              icon: "orders",
              to: "Catalogo",
              color: "warning",
            },
            {
              id: 46,
              name: "Catalogo Materia Prima",
              icon: "orders",
              to: "Material",
              color: "warning",
            },
            {
              id: 21,
              name: "Proveedores",
              icon: "store",
              color: "#FFCA28",
              to: "Proveedores",
            },
            {
              id: 41,
              name: "Vendedores",
              icon: "account_tie",
              to: "Vendedores",
              color: "warning",
            },
            {
              id: 45,
              name: "Empleados",
              icon: "employees",
              to: "Empleados",
              color: "warning",
            },
            {
              id: 44,
              name: "Admin Usuarios",
              icon: "account",
              to: "Usuarios",
              color: "warning",
            },
            {
              id: 40,
              name: "Clientes",
              icon: "accountGroup",
              to: "Clientes",
            },
          ],
        },
        {
          id: 99,
          name: "About us",
          icon: "info",
          to: "About",
          color: "warning",
        },
        {
          id: 8,
          name: "Truchas",
          icon: "fish",
          color: "red",
          children: [
            {
              id: 81,
              name: "Calculadora",
              icon: "store",
              color: "#EF5350",
              to: "Calculadora",
            },
          ],
        },
      ],
    },
    items: [
      {
        id: 0,
        name: "Home",
        icon: "home",
        to: "/",
        color: "#CDDC39",
      },

      {
        id: 2,
        name: "Compras",
        icon: "truck",
        color: "#FFCA28",
        children: [
          {
            id: 20,
            name: "Crear Orden de compra",
            icon: "store",
            color: "#FFCA28",
            to: "compras",
          },
          {
            id: 22,
            name: "Reporte Ingreso Mercancias",
            icon: "store",
            color: "#FFCA28",
            to: "store",
          },
        ],
      },
      {
        id: 3,
        name: "Inventario",
        icon: "warehouse",
        color: "red",
        children: [
          {
            id: 32,
            name: "Inventario",
            icon: "store",
            color: "#EF5350",
            to: "Inventario",
          },
          {
            id: 33,
            name: "Movimientos de Inventario",
            icon: "store",
            color: "#EF5350",
            to: "store",
          },
          {
            id: 34,
            name: "Garantías",
            icon: "store",
            color: "#EF5350",
            to: "store",
          },
          {
            id: 36,
            name: "Traslado a Promoción",
            icon: "store",
            color: "#EF5350",
            to: "store",
          },
          {
            id: 37,
            name: "Traslado de Bodega",
            icon: "store",
            color: "#EF5350",
            to: "store",
          },
          {
            id: 38,
            name: "Traslados",
            icon: "store",
            color: "#EF5350",
            to: "store",
          },
          {
            id: 39,
            name: "Rotación",
            icon: "store",
            color: "#EF5350",
            to: "store",
          },
        ],
      },
      {
        id: 1,
        name: "Ventas",
        icon: "cash",
        color: "#CDDC39",
        children: [
          {
            id: 11,
            name: "Comprobante de Ingreso",
            icon: "orders",
            to: "Receipt_in",
            color: "#CDDC39",
          },
          {
            id: 12,
            name: "Comprobante de Egreso",
            icon: "orders",
            to: "Receipt_out",
            color: "#CDDC39",
          },

          {
            id: 15,
            name: "Devolución",
            icon: "orders",
            to: "Contents",
            color: "#CDDC39",
          },
        ],
      },
      {
        id: 4,
        name: "Configuración",
        icon: "set",
        children: [
          {
            id: 42,
            name: "Centros Operativos",
            icon: "cash",
            to: "Pos",
            color: "warning",
          },
          {
            id: 43,
            name: "Catalogo",
            icon: "orders",
            to: "Catalogo",
            color: "warning",
          },
          {
            id: 46,
            name: "Catalogo Materia Prima",
            icon: "orders",
            to: "Material",
            color: "warning",
          },
          {
            id: 21,
            name: "Proveedores",
            icon: "store",
            color: "#FFCA28",
            to: "Proveedores",
          },
          {
            id: 41,
            name: "Vendedores",
            icon: "account_tie",
            to: "Vendedores",
            color: "warning",
          },
          {
            id: 45,
            name: "Empleados",
            icon: "employees",
            to: "Empleados",
            color: "warning",
          },
          {
            id: 44,
            name: "Admin Usuarios",
            icon: "account",
            to: "Usuarios",
            color: "warning",
          },
          {
            id: 40,
            name: "Clientes",
            icon: "accountGroup",
            to: "Clientes",
          },
        ],
      },
      {
        id: 99,
        name: "About us",
        icon: "info",
        to: "About",
        color: "warning",
      },
    ],
    tracker: null,
  }),
  mixins: [update],
  components: { ChangePassword },
  mounted() {
    this.company = window.company;
    //this.tracking();
  },
  computed: {
    showDrawer() {
      return this.$store.getters.getMenuStatus && this.drawer;
    },
  },
  watch: {
    "$store.getters.getMenuStatus"(newVal) {
      if (newVal) {
        this.drawer = true;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.items = this.menu[newVal.user_access];
        this.track();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.tracker);
  },
  methods: {
    ChangePasswordbtn() {
      this.item= {
          account: window.profile.account,
          code: window.profile.code,
          store_id: window.company.code,
          user_email: window.profile.email,
          user_name: window.profile.name,
          user_password: "",
      };
      this.dialog= true;
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    updateForm(item) {
      //if (item.length > 0) console.log(item[0].name, item[0].to);
      if (item[0].to) this.$router.push(item[0].to);
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    logout() {
      this.$store.state.navStatus = false;
      this.$router.push("/");
    },
    track() {
      this.tracker = setInterval(async () => {
        var d = new Date().getHours(); // for now
        if (d >= TIME_IN_BOUNDARY && d <= TIME_OUT_BOUNDARY) {
          try {
            const res = await this.getLocation();
            var dta = {
              account: this.$store.state.profile.account,
              user_code: this.$store.getters.profile.code,
              user_name: this.$store.getters.profile.name,
              location: JSON.stringify(res),
            };
            var qry = {
              account: this.$store.state.profile.account,
              table: "tracking",
              data: [dta],
            };
            webserver("put_table", qry, () => {});
          } catch (e) {
            console.error(e);
          }
        }
      }, INTERVAL);
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              resolve([res.coords.latitude, res.coords.longitude]);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>

.v-main {
  padding-top: 64px !important;
}
.bg-app {
  /* background: url("./assets/bg3.jpeg"); */
  background: rgba(0, 0, 0, 0.134);
  background-repeat: repeat;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.navigation-drawer {
  font-size: 1em;
  font-family: "Roboto Condensed", sans-serif;
}
</style>
