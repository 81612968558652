<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="480px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar>
              <v-app-bar-title>Cuenta de acceso</v-app-bar-title>
              <v-spacer></v-spacer>
              <v-btn x-small color="info" fab @click="close()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  readonly
                  v-model="item.user_name"
                  id="user_name"
                  :rules="f_required"
                  label="Nombre"
                  hide-details="auto"
                  required
                  outlined
                  disabled
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  v-model="item.user_email"
                  id="address"
                  :rules="f_required"
                  label="Correo Electrónico"
                  hide-details="auto"
                  required
                  outlined
                  disabled
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="actual_password"
                  :rules="codeRules"
                  ref="actual_password"
                  label="Contraseña actual"
                  prepend-inner-icon="mdi-lock"
                  type="password"
                  hide-details="auto"
                  autocomplete="off"
                  clearable
                  outlined
                  required
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="new_password"
                  ref="new_password"
                  :rules="codeRules_a"
                  type="password"
                  prepend-inner-icon="mdi-lock-outline"
                  clearable
                  label="Nueva Contraseña"
                  hide-details="auto"
                  autocomplete="off"
                  outlined
                  required
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="new_password2"
                  type="password"
                  :rules="codeRules_b"
                  prepend-inner-icon="mdi-lock-outline"
                  clearable
                  autocomplete="off"
                  label="Verificar nueva contraseña"
                  hide-details="auto"
                  outlined
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-app-bar>
              <v-spacer></v-spacer>
              <v-btn large text color="info" type="submit"> Guardar </v-btn>
            </v-app-bar>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createUser.js";
export default {
  name: "PartyForm",
  components: {},
  data() {
    return {
      actual_password: "",
      new_password: "",
      new_password2: "",
      password_value: String,
      loading_status: false,
      tab: null,
      errors: createItem(),
      dialog_confirm: false,
      compressed_image: null,
      reg_delete: null,
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length > 6 || "EL código debe tener mínimo 6 caracteres.",
      ],
      codeRules_a: [
        (v) => !!v || "El código es requerido",
        (v) => v.length > 6 || "EL código debe tener mínimo 6 caracteres.",
      ],
      codeRules_b: [
        (v) => !!v || "El código es requerido",
        (v) => v.length > 6 || "EL código debe tener mínimo 6 caracteres.",
        (v) =>
          v === this.$refs.new_password.value ||
          "Verifique la clave no coninside",
        (v) =>
          v !== this.$refs.actual_password.value ||
          "No puede utilizar la misma clave",
      ],
      access_levels: [
        { value: "Owner", text: "Propietario" },
        { value: "Admin", text: "Administrador" },
        { value: "Sales", text: "Ventas" },
        { value: "Orders", text: "Compras" },
        { value: "Trout", text: "Trucha" },
        { value: "Promotor", text: "Promotor" },
        { value: "Supervisor", text: "Supervisor" },
        { value: "Digitador", text: "Digitador" },
        { value: "Chef", text: "Chef" },
        { value: "QR4", text: "QR4" },
        { value: "Providers", text: "Proveedores" },
      ],
      storeLst: [],
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    item: function () {
      //console.log(e);
    },
  },
  mounted() {
    //console.log(this.item);
  },
  methods: {
    item_submit(e) {
      var md5 = require("md5");
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      this.item.user_password = md5(this.$refs.actual_password.value);

      if (this.valid) {
        console.log(this.item);
        var qry = {
          user_email: this.item.user_email,
          user_password: this.item.user_password,
        };
        webserver("pre_login", qry, (data) => {
          if (data.length > 0) {
            var qry = {
              account: this.$store.state.profile.account,
              table: "users",
              data: [
                {
                  account: this.item.account,
                  code: this.item.code,
                  store_id: this.item.store_id,
                  user_email: this.item.user_email,
                  user_password: md5(this.$refs.new_password.value),
                },
              ],
            };
            webserver("put_table", qry, () => {
              this.$store.state.navStatus = false;
              this.$store.state.profile.token = null;
              this.$router.push("/login/");
              this.close();
            });
          }
        });
      }
    },
    close() {
      this.$emit("close", true);
    },
  },
};
</script>

<style>
</style>