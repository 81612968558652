import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/SmartChef.vue'
import store from "../store"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '/',
        component: Welcome,
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Welcome',
        name: 'Welcome',
        component: () =>
            import('../views/Welcome.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Registration',
        name: 'Registration',
        component: () =>
            import('../views/Registration.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Ingreso',
        name: 'Ingreso',
        component: () =>
            import('../views/Clients/Login.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Sales_stores',
        name: 'Sales_stores',
        component: () =>
            import('../views/sales/Stores.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/QR4',
        name: 'QR4',
        component: () =>
            import('../views/QR4.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Cash_report',
        name: 'Cash_report',
        component: () =>
            import('../views/Cash_report.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/BusinessCard',
        name: 'BusinessCard',
        component: () =>
            import('../views/BusinessCard.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/registration',
        name: 'registration',
        component: () =>
            import('../views/Contact.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/WelcomeChef',
        name: 'WelcomeChef',
        component: () =>
            import('../views/chef/Welcome.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_plates',
        name: 'Chef_plates',
        component: () =>
            import('../views/chef/Chef_plates.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_Supplier',
        name: 'Chef_Supplier',
        component: () =>
            import('../views/chef/Chef_Supplier.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_Store',
        name: 'Chef_Store',
        component: () =>
            import('../views/chef/Chef_Store.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_Supplier_Catalogue',
        name: 'Chef_Supplier_Catalogue',
        component: () =>
            import('../views/chef/Chef_Supplier_Catalogue.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_cost_analysis',
        name: 'Chef_cost_analysis',
        component: () =>
            import('../views/chef/Chef_cost_analysis.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_orders',
        name: 'Chef_orders',
        component: () =>
            import('../views/chef/Chef_orders.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Chef_delivery',
        name: 'Chef_delivery',
        component: () =>
            import('../views/chef/Chef_delivery.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/mypeople',
        name: 'mypeople',
        component: () =>
            import('../views/TrackerMap.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/StoresMap',
        name: 'StoresMap',
        component: () =>
            import('../views/StoresMap.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/DashBoard',
        name: 'DashBoard',
        component: () =>
            import('../views/DashBoard.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Receipt_in',
        name: 'Receipt_in',
        component: () =>
            import('../views/Receipt_in.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Receipt_out',
        name: 'Receipt_out',
        component: () =>
            import('../views/Receipt_out.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Proveedores',
        name: 'Proveedores',
        component: () =>
            import('../views/Supplier.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Inventario',
        name: 'Inventory',
        component: () =>
            import('../views/Inventory.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/About',
        name: 'About',
        component: () =>
            import('../views/About.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Login',
        name: 'Login',
        props: true,
        component: () =>
            import('../views/Login.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Clientes',
        name: 'Clientes',
        props: true,
        component: () =>
            import('../views/Clients.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Vendedores',
        name: 'Vendedores',
        props: true,
        component: () =>
            import('../views/Vendors.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Empleados',
        name: 'Empleados',
        props: true,
        component: () =>
            import('../views/Employees.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Pos',
        name: 'Pos',
        props: true,
        component: () =>
            import('../views/Stores.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/chef_tpv',
        name: 'chef_tpv',
        props: true,
        component: () =>
            import('../views/chef/Chef_tpv.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/kitchen',
        name: 'kitchen',
        props: true,
        component: () =>
            import('../views/chef/Kitchen.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/Usuarios',
        name: 'Usuarios',
        props: true,
        component: () =>
            import('../views/Users.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Productos',
        name: 'Productos',
        props: true,
        component: () =>
            import('../views/Products.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Catalogo',
        name: 'Catalogo',
        props: true,
        component: () =>
            import('../views/Catalogue.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Material',
        name: 'Material',
        props: true,
        component: () =>
            import('../views/Raw_Material.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Compras',
        name: 'Compras',
        props: true,
        component: () =>
            import('../views/Stock_order.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Calculadora',
        name: 'Calculadora',
        props: true,
        component: () =>
            import('../views/Trout_calculator.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/TroutFeed',
        name: 'TroutFeed',
        props: true,
        component: () =>
            import('../views/Trout_feed.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
    {
        path: '/Settings',
        name: 'Settings',
        props: true,
        component: () =>
            import('../views/Settings.vue'),
        meta: {
            requiresAuth: false,
            is_admin: false
        }
    },
    {
        path: '/FishEvents',
        name: 'FishEvents',
        props: true,
        component: () =>
            import('../views/fish/Fish_events.vue'),
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.profile) {
            if (store.state.profile.token) {
                next()
            } else {
                next({
                    name: 'Login',
                    params: { nextUrl: to.fullPath }
                })
            }
            return
        } else {
            next({
                name: 'Login',
                params: { nextUrl: to.fullPath }
            })
        }
    } else {
        next()
    }
})

export default router